import { QueryClient } from "@tanstack/react-query"
import { ApiError } from "src/shared/services/api"

export class TimeframeTooShort extends Error {
  span: number
  timeframe: number

  constructor(timeframe: number, span: number) {
    super(`Time frame too short for the accumulation period`)
    this.span = span
    this.timeframe = timeframe
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      useErrorBoundary: false,
    },
    queries: {
      retry: (failureCount, error) =>
        (error instanceof ApiError && error.status >= 400 && error.status < 500) ||
        error instanceof TimeframeTooShort
          ? false
          : 3 - failureCount > 0,
      staleTime: 1000,
      suspense: true,
    },
  },
})
