export const isFromBackOffice = () => !!getBackOfficeUserUuid()

export const getBackOfficeUserUuid = () => sessionStorage.getItem("backOfficeUserUuid")

export const clearFromBackOfficeUserUuid = () => sessionStorage.removeItem("backOfficeUserUuid")

const setFromBackOfficeUserUuid = (value: string) =>
  sessionStorage.setItem("backOfficeUserUuid", value)

export const checkIsFromBackoffice = () => {
  const { search } = window.location
  const urlSearch = new URLSearchParams(search)
  const backOfficeUserUuid = urlSearch.get("backOfficeUserUuid")
  if (!!backOfficeUserUuid) {
    setFromBackOfficeUserUuid(backOfficeUserUuid)
  }
}
